import React from 'react';
import './aboutUs.scss';

const AboutUs = () => {
  return (
    <div>
    <h1 className="welcome">Bine ați venit !</h1>
      <h2>Despre noi</h2>
      <p className="app-text about-description">
        Inaugurat în 2019, Hanul de Vis este o locație confortabilă și liniștită la marginea Munților Apuseni, situată într-un cadru natural, departe de 
        stresul și agitația vieții cotidiene. Aici veți găsi un refugiu pentru minte și corp, timpul petrecut aici fiind o oportunitate de a regăsi legătura 
        cu natura, element care lipsește din viața majorității dintre noi.
      </p>
      <div className="video-container">
        <video className="video" controls>
          <source src="/photos/hanuldevis.mp4" type="video/mp4"></source>
        </video>
        {/*<iframe title="Video" className="youtube-video" src="https://www.youtube.com/embed/_DEgQ9YCPjk"></iframe>*/}
      </div>
    </div>
  );
}

export default AboutUs